import React from 'react'
import Header from './header'


import '../styles/layout.css'


const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle, onHideNavSearch, onShowNavSearch, showNavSearch}) => (
  <>
    <Header 
      siteTitle={siteTitle}
      onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav}
      onHideNavSearch={onHideNavSearch} onShowNavSearch={onShowNavSearch} showNavSearch={showNavSearch}
    />
    {children}
  </>
)

export default Layout
